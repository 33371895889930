import { useState } from 'react';

export const useBoolean = (defaultValue = false) => {
  const [value, setValue] = useState(defaultValue);

  const setFalse = () => setValue(false);
  const setTrue = () => setValue(true);
  const toggle = (flag?: boolean) => setValue((prev) => flag ?? !prev);

  return [value, { setFalse, setTrue, toggle }] as const;
};
