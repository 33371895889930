import React from 'react';
import { X } from 'lucide-react';
import { MapTypes } from 'src/@types/Map.types';
import { cn } from 'src/utils/cn';
import { Icon, IconNames } from '../Icon/Icon';

const chipSizeClasses = {
  large: 'text-lg',
  medium: 'text-base',
  regular: 'text-sm',
  small: 'text-xs',
};

type ChipSize = keyof typeof chipSizeClasses;

type Props = React.HTMLAttributes<HTMLDivElement> & {
  data: MapTypes.Category;
  size?: ChipSize;
  withIcon?: boolean;
  withBorder?: boolean;
  onDelete?: React.MouseEventHandler<HTMLElement>;
  forceIconSpace?: boolean;
  'data-testid'?: string;
};

export function CategoryChip({
  data,
  withIcon = false,
  withBorder = true,
  size = 'regular',
  className,
  style,
  onDelete,
  forceIconSpace = false,
  'data-testid': testId = 'category-chip',
  ...props
}: Props) {
  return (
    <div
      className={cn(
        'flex max-w-full items-center justify-center gap-2 font-normal uppercase',
        chipSizeClasses[size],
        {
          'border border-primary': withBorder,
          'hover:cursor-pointer hover:bg-backgroundLight': Boolean(
            props.onClick
          ),
        },
        className
      )}
      style={{
        borderColor: data.color ?? '',
        color: data.color ?? '',
        ...style,
      }}
      key={data.id}
      {...props}
    >
      {withIcon && data.icon && (
        <div className="my-1 h-4 w-4 first:ml-3">
          <Icon
            name={data.icon as IconNames}
            size="16"
            data-testid={`${testId}-icon`}
            className="flex-shrink-0 stroke-2 text-sm"
          />
        </div>
      )}
      <div
        className={cn('my-1 truncate first:ml-3 last:mr-3', {
          'pl-6': !data.icon && forceIconSpace,
        })}
      >
        {data.name}
      </div>
      {onDelete && (
        <div
          className="group/icon cursor-pointer py-1 last:pr-3"
          onClick={onDelete}
          data-testid={`${testId}-delete`}
        >
          <X className="group-hover/icon:stroke-2" />
        </div>
      )}
    </div>
  );
}
