'use client';

import { useState } from 'react';
import { PlusIcon, TagsIcon } from 'lucide-react';
import { CategoryChip } from 'src/components/CategoryChip/CategoryChip';
import { Icon, IconNames } from 'src/components/Icon/Icon';
import * as Select from 'src/components/Select/Select';
import { cn } from 'src/utils/cn';

type Category = {
  label: string;
  icon: IconNames;
  color: string;
};

type Props = React.PropsWithChildren<{
  preSelectedCategories: Category[];
  categories: Category[];
}>;

const exampleCategoryToDBCategory = (category: Category) => ({
  color: category.color,
  created_at: '',
  icon: category.icon as string,
  id: category.label,
  map_id: '',
  name: category.label,
  updated_at: '',
});

function getCategoryColor(color: string | null, selected: boolean) {
  return selected ? 'white' : color ?? '';
}

function getCategoryBackgroundColor(color: string | null, selected: boolean) {
  if (color) {
    return selected ? color : '';
  }
  return selected ? 'rgb(var(--dark))' : '';
}

export function WelcomePageCategorySelect(props: Props) {
  const [selectedCategories, setSelectedCategories] = useState(
    props.preSelectedCategories.map((category) => ({
      ...category,
      selected: true,
    }))
  );

  const options = props.categories.map((category) => ({
    ...category,
    selected: selectedCategories.some((c) => c.label === category.label),
  }));

  const placeMarkerColor = selectedCategories[0]?.color ?? '';
  const placeMarkerIcon = selectedCategories[0]?.icon ?? 'MapPin';

  const onCategorySelect = (value: string[]) => {
    const selected = props.categories
      .filter((category) => value.includes(category.label))
      .map((category) => ({
        ...category,
        selected: true,
      }));

    setSelectedCategories(selected);
  };

  return (
    <>
      <Select.Root
        value={selectedCategories.map((category) => category.label)}
        onChange={onCategorySelect}
      >
        <Select.Trigger className="z-10">
          <div
            className={cn(
              'group flex w-full flex-wrap items-center gap-2 bg-backgroundLight p-2',
              'hover:cursor-pointer hover:shadow-md'
            )}
          >
            {selectedCategories.map((category) => (
              <CategoryChip
                data={exampleCategoryToDBCategory(category)}
                key={category.label}
                className="bg-backgroundLight"
                withIcon
              />
            ))}
            {selectedCategories.length === 0 && (
              <>
                <TagsIcon />
                <div className="text-xl">Dodaj kategorię do miejsca</div>
              </>
            )}
            {selectedCategories.length !== 0 && (
              <div className="flex items-center px-2 py-1 text-sm uppercase">
                <PlusIcon className="mr-1 flex-shrink-0 text-xl" />
                Dodaj
              </div>
            )}
          </div>
        </Select.Trigger>
        <Select.Portal className="px-8 sm:px-0">
          {options.length !== 0 && (
            <p className="text-center text-xl sm:text-sm">Wybierz kategorie</p>
          )}
          {options.map((category) => (
            <Select.Option key={category.label} value={category.label}>
              <CategoryChip
                data={exampleCategoryToDBCategory(category)}
                withIcon
                size="large"
                withBorder={false}
                className="cursor-pointer justify-start hover:bg-background"
                forceIconSpace
                style={{
                  background: getCategoryBackgroundColor(
                    category.color,
                    category.selected
                  ),
                  color: getCategoryColor(category.color, category.selected),
                }}
              />
            </Select.Option>
          ))}
          {options.length === 0 && (
            <p className="text-center text-xl sm:text-sm">
              Brak kategorii do wyboru
            </p>
          )}
        </Select.Portal>
      </Select.Root>
      {props.children}
      {/* place marker */}
      <div className="absolute left-1/3 top-1/2">
        <div
          className={cn(
            'group relative flex h-10 w-10 items-center justify-center rounded-full bg-primary stroke-2 text-background transition hover:scale-110 hover:cursor-pointer'
          )}
          style={{
            backgroundColor: placeMarkerColor,
          }}
        >
          <Icon name={placeMarkerIcon} className={cn('stroke-[1.5px]')} />
          <div
            className={cn(
              'absolute -bottom-3 h-0 w-0 border-x-[12px] border-t-[16px] border-primary border-x-transparent transition group-hover:scale-110'
            )}
            style={{
              borderTopColor: placeMarkerColor,
            }}
          />
        </div>
      </div>
    </>
  );
}
