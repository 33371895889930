'use client';

import { Suspense, useMemo } from 'react';
import type Lucide from 'lucide-react';
import type { LucideProps } from 'lucide-react';
import * as LucideIcons from 'lucide-react';
import { cn } from 'src/utils/cn';

export type IconNames = keyof typeof Lucide.icons;

type Props = LucideProps & {
  name: IconNames;
  hoverable?: boolean;
  onPick?: (icon: string) => void;
};

export function Icon({
  name,
  hoverable = false,
  onPick,
  className,
  ...props
}: Props) {
  const LucideIcon = useMemo(() => LucideIcons[name], [name]);

  const handleOnClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(e);
    }
    onPick?.(name);
  };

  if (!LucideIcon) {
    throw Error(`Icon ${name} not found`);
  }

  return (
    <Suspense
      fallback={<div className={cn('h-6 w-6 flex-shrink-0', className)} />}
    >
      <LucideIcon
        className={cn(
          {
            ['hover:stroke-2']: hoverable,
            ['stroke-1']: hoverable,
          },
          className
        )}
        onClick={handleOnClick}
        {...props}
      />
    </Suspense>
  );
}
